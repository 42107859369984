var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"},on:{"scroll":function($event){return _vm.handleScroll()}}},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),(
    _vm.$route.name &&
    !(_vm.$route.name.startsWith('site') || _vm.$route.name === 'user-wallet' || _vm.$route.name === 'account-login' || _vm.$route.name === 'account-register' || _vm.$route.name === 'account-wallet')
  )?_c('HeaderPage',{attrs:{"isScrolled":_vm.isScrolled,"isScrolledMobile":_vm.isScrolledMobile}}):_vm._e(),_c('main',{staticClass:"content-main"},[_c('router-view')],1),_c('ModalNewEvents'),(
    _vm.$route.name &&
    !(_vm.$route.name.startsWith('site') || _vm.$route.name === 'user-wallet' || _vm.$route.name === 'account-login' || _vm.$route.name === 'account-register' || _vm.$route.name === 'account-wallet')
  )?_c('FooterPage'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }